import React, { useEffect, useState } from 'react'
import SliderJsx from '../component/slider';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase.config';

const CategoryRoute = () => {
  const navigate = useNavigate()
  const [categoryD, setCategory] = useState([]);

  //need to fetch from firebase - as this is a direct
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories'));
        const categoryData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategory(categoryData);
        console.log(categoryData);
        console.log('Data Logged');

      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchCategory();
  }, [])

  const categoryArray = [
    {
      name: 'Money Bank',
      image: 'https://res.cloudinary.com/joinventures/image/upload/banners/home_hacks_propshop24_lenses_20230514.png',
      categoryId: '75248'
    },
    {
      name: 'Pencil Box',
      image: 'https://res.cloudinary.com/joinventures/image/upload/banners/organisers_storage_propshop24_lenses_20230514.png',
      categoryId: '75248'
    },
    {
      name: 'Pencil Pouch EVA',
      image: 'https://res.cloudinary.com/joinventures/image/upload/banners/kitchen_dining_propshop24_lenses_20230514.png',
      categoryId: '75248'
    },
    {
      name: 'Water Bottle',
      image: 'https://res.cloudinary.com/joinventures/image/upload/banners/lights_lamps_propshop24_lenses_20230514.png',
      categoryId: '75248'
    },
    {
      name: 'Pens',
      image: 'https://res.cloudinary.com/joinventures/image/upload/banners/lunch_boxes_d_lenses_1666_20240212181524.jpg',
      categoryId: '75248'
    }
  ];
  return (
    <>
      {/* <SliderJsx /> */}
      <div className='categoryRoute'>
        {categoryD.map((category, index) => (
          <div className="category-div-fs01" key={index} onClick={() => {
            navigate(`/shop/${category.categoryId}`)
          }}>
            <div className="category-img--div--fs01">
              <img src={category.categoryImg || category.galleryImageUrl} alt={category.categoryName} />
            </div>
            <div className="category-item">{category.categoryName}</div>
          </div>
        ))}
      </div>
    </>
  )
}

export default CategoryRoute
